<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);
const props = defineProps({
    checked: {
        type: [Array, Boolean],
        required: true,
    },
    value: {
        default: null,
    },
});
const proxyChecked = computed({
    get() {
        return props.checked;
    },
    set(val) {
        emit('update:checked', val);
    },
});
</script>

<script>
export default {
    inheritAttrs: false
};
</script>

<template>
    <div class="flex flex-row gap-x-2">
        <input
            type="checkbox"
            :value="value"
            v-model="proxyChecked"
            v-bind="$attrs"
            class="
                bg-gray-200

                border-0

                checked:bg-gray-900

                hover:bg-gray-300

                border-gray-300

                text-gray-800

                focus:ring-3
                focus:ring-orange-400/50

                rounded
                mr-2
            ">

        <slot />
    </div>
</template>
